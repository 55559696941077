import useWindowDimensions from '../../hooks/useWindowDimensions'

type Props = {
  desktopVideo: string
  mobileVideo: string
  children?: JSX.Element | JSX.Element[]
  desktopPoster: string
  mobilePoster: string
}

const BackgroundVideo = ({
  desktopVideo,
  mobileVideo,
  children,
  desktopPoster,
  mobilePoster,
}: Props) => {
  const { width } = useWindowDimensions()

  return (
    <>
      {width > 480 ? (
        <video
          autoPlay
          loop
          playsInline
          disablePictureInPicture
          muted
          className="video"
          preload="auto"
          poster={desktopPoster}
        >
          <source src={desktopVideo} type="video/mp4" />
          <source src={desktopVideo} type="video/ogg" />
        </video>
      ) : (
        <video
          autoPlay
          loop
          playsInline
          disablePictureInPicture
          muted
          className="video"
          preload="auto"
          poster={mobilePoster}
        >
          <source src={mobileVideo} type="video/mp4" />
          <source src={mobileVideo} type="video/ogg" />
        </video>
      )}
      {children}
    </>
  )
}

export default BackgroundVideo
