import Chart from '../LineChart/LineChart'
import Logo from '../Logo/Logo'
import morotco from '../../assets/morotco.svg'
import BackgroundVideo from '../BackgroundVideo/BackgroundVideo'
import desktopPoster from '../../assets/video backgrounds/frame1-desktop.jpg'
import mobilePoster from '../../assets/video backgrounds/frame1-mobile.jpg'
import './App.css'

declare global {
  interface Window {
    ____TREE_DATA: any
  }
}

const trees: [{ trees: number; date: number }] = window.____TREE_DATA

const App = () => {
  const desktopVideoSrc =
    'https://www.morot.co/media/purchase/background-desktop.mp4'
  const mobileVideoSrc =
    'https://www.morot.co/media/purchase/background-mobile.mp4'

  const number_of_trees = String(trees[trees.length - 1].trees)
  const data = [
    {
      color: 'white',
      points: trees.map(({ date, trees }) => ({ x: String(date), y: trees })),
    },
  ]

  return (
    <BackgroundVideo
      desktopVideo={desktopVideoSrc}
      mobileVideo={mobileVideoSrc}
      desktopPoster={desktopPoster}
      mobilePoster={mobilePoster}
    >
      <div className="content">
        <div className="sub-content">
          <h1>
            {number_of_trees.slice(0, -3)} {number_of_trees.slice(-3)}
          </h1>
          <div className="sub-content-info">
            <p>Träd planterade</p>
            <p>sedan 2019</p>
          </div>
          <Chart data={data} />
          <p className="content-info" style={{ display: 'none' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore
          </p>
          <a href="https://onetreeplanted.org/">Läs mer hos One Tree Planted</a>
        </div>
        <div className="footer">
          <Logo src={morotco} />
        </div>
      </div>
    </BackgroundVideo>
  )
}

export default App
