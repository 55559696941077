import LineChart from 'react-linechart'
import 'react-linechart/dist/styles.css'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import './LineChart.css'

type Props = {
  data: {
    color: string
    points: Array<{ x: string; y: number }>
  }[]
}

const Chart = ({ data }: Props) => {
  const { height, width } = useWindowDimensions()
  return (
    <div className="line-chart-container">
      <LineChart
        width={width < 500 ? width - 16 : 400}
        height={height < 600 ? 120 : 181}
        data={data}
        hideYAxis
        hideXLabel
        hidePoints
        isDate
        xDisplay={(input: Date): string => {
          if (input.getMonth() === 0 && input.getDate() === 1) {
            return String(input.getFullYear())
          }

          return ''
        }}
        interpolate="basis"
      />
    </div>
  )
}
export default Chart
