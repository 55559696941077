import './Logo.css'

type Props = {
  src: string
}
const Logo = ({ src }: Props) => {
  return <img className="footer-logo" src={src} alt="Logo" />
}

export default Logo
